FontAwesomeConfig = { searchPseudoElements: true };
import '@fortawesome/fontawesome-free/js/fontawesome';
import '@fortawesome/fontawesome-free/js/solid';
import '@fortawesome/fontawesome-free/js/brands';
import "splitting/dist/splitting.css";
import "splitting/dist/splitting-cells.css";
import Splitting from "splitting";

jQuery(document).ready(function($) {
 /* ====================== INIT ======================  */
  Splitting();
  const wordElements = document.querySelectorAll('.masthead h1 .word');

  wordElements.forEach(wordElement => {
    const span = document.createElement('span');
    while (wordElement.firstChild) {
      span.appendChild(wordElement.firstChild);
    }
    wordElement.appendChild(span);
  });

 /* ====================== REMOVE EMPTY P TAG ======================  */
    $('p').each(function() {
        var $this = $(this);
        if ($this.html().replace(/\s|&nbsp;/g, '').length == 0)
            $this.remove();
    });

/* ====================== SCROLL TOP ======================  */
  $(".scroll-top").click(function() {
    $("html, body").animate({ scrollTop: 0 }, 1500);
    return false;
  });

/* ====================== NAV ======================  */
   $(document).on('click', '.header__nav-toggle:not(.is-opened)', function() {
        setTimeout(function() {
          $('.header__nav-toggle').removeClass('is-toggled');
        }, 500);
    });

    $('.header__nav-toggle').unbind().on("click", function() {
        $('.header').toggleClass('header--nav-active');
        $(this).toggleClass('is-opened');
        $('html,body').toggleClass('noscroll');

        setTimeout(function() {
          $('.header__nav-toggle').addClass('is-toggled');
        }, 500);
    });

/* ====================== CONTACT FORM TOGGLE ======================  */
    $('.contact-form-toggle').unbind().on("click", function(e) {
        e.preventDefault();
        $('.contact-modal').addClass('contact-modal--active');
        $('html,body').addClass('noscroll');
    });

    $('.contact-modal__close').unbind().on("click", function(e) {
        e.preventDefault();
        $('.contact-modal').removeClass('contact-modal--active');
        $('html,body').removeClass('noscroll');
    });
});
