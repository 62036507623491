var $ = require("jquery");
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);



document.querySelectorAll('[data-splitting="words"]:not(.no-anim)').forEach(function(heading) {
	gsap.delayedCall(.5, animateWords);
	var duration = 1.4;
	var stagger = 0.05;

  function animateWords() {
    gsap.to(heading.querySelectorAll(".word"), {
      scrollTrigger: {
        trigger: heading,
        start: 'top 80%',
      },
      webkitClipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
      clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
      ease: "power4.out",
      opacity: 1,
      duration: duration,
      stagger: stagger
    });

    gsap.to(heading.querySelectorAll(".word span"), {
      scrollTrigger: {
        trigger: heading,
        start: 'top 80%',
      },
      y: 0,
      ease: "power4.out",
      duration: duration,
      stagger: stagger
    });
  };
});
