var $ = require("jquery");
// import Swiper bundle with all modules installed
import Swiper from 'swiper/bundle';
// import styles bundle
import 'swiper/css/bundle';

/* ====================== SERVICES SLIDER ======================  */
var loginSlider = new Swiper('.login-wrap__bg', {
  slidesPerView: 1,
  effect: "fade",
  loop: true,
  speed: 2000,
  autoplay: {
    delay: 3500,
  },
});

var mySwiper = new Swiper('.slider .swiper', {
  slidesPerView: 1,
  effect: "fade",
  loop: true,
  pagination: {
    el: '.slider .swiper-pagination',
    clickable: true,
    renderBullet: function (index, className) {
      var slide = document.querySelector('.swiper-slide[data-swiper-slide-index="' + index + '"]');
      var dataAttribute = slide.getAttribute('data-label');
      return '<span class="' + className + '">' + dataAttribute + '</span>';
    },
  },
});
